import { BarcodeOutlined, FileAddOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Modal, Pagination, Row, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiWms } from '../../api';
import { sortByDate } from '../../utils/date';
import { envs } from '../../utils/env';
import errorHandler from '../../utils/errorHandler';
import { Loading } from '../Loading';
import { InventoryFilter } from './components/InventoryFilters';
import { InventoryTrail } from './components/inventoryTrail';
import { getInventoreyStatsMapper } from './components/utils';
import { InventoryFormHandler } from './forms/InventoryFormHandler';
import { IInventoryCustomFromData } from './forms/InventoryInboundForm';
import { IInventoryAdjustCustomFormFields } from './forms/ScannedStockForm';
import styles from './index.module.scss';
import exportSvg from 'shared/svgs/export_svg';

const TrailType = [
	{ label: 'Inventory', key: '1', icon: <FileAddOutlined /> },
	{ label: 'Scanned', key: '2', icon: <BarcodeOutlined /> }
];

export const DEFAULT_INVENTORY_TRAIL_FILTER = {
	trailsBetweenTime: {
		start: dayjs().subtract(30, 'days').format('YYYY/MM/DD'),
		end: dayjs().add(1, 'day').format('YYYY/MM/DD')
	},
	inventoryTrailFilters: '',
	page: 1
};

interface IInventoryPopup {
	inventoryId: string;
	onClose: () => void;
	onOk: () => void;
	wsin: string;
	reloadList?: () => void;
	setReloadInventoryList: React.Dispatch<React.SetStateAction<number>>;
	isWMS: boolean;
}

export const InventoryPopup = (props: IInventoryPopup) => {
	const { inventoryId, onClose, onOk, wsin, reloadList, setReloadInventoryList, isWMS } = props;
	const { wmsBaseUrl, appType } = envs;

	const [inventoryData, setInventoryData] = useState<any>({});
	const [inventoryTrailData, setInventoryTrailData] = useState<any[]>([]);
	const [scannedStockTrail, setScannedStockTrail] = useState([]);
	const [shouldShowInventoryAdjustForm, setShouldShowInventoryAdjustForm] = useState<string>('1');
	const [trailLoading, setTrailLoading] = useState<boolean>(false);
	const [trailType, setTrailType] = useState('1');
	const [pageCount, setPageCount] = useState(0);
	const [inventoryInboundFormData, setInventoryInboundFormData] = useState<typeof IInventoryCustomFromData>(
		() => IInventoryCustomFromData
	);
	const [inventoryAdjustFormData, setInventoryAdjustFormData] = useState<typeof IInventoryAdjustCustomFormFields>(
		() => IInventoryAdjustCustomFormFields
	);
	const [filter, setFilter] = useState<typeof DEFAULT_INVENTORY_TRAIL_FILTER>(() => DEFAULT_INVENTORY_TRAIL_FILTER);

	const getInventoryDetail = useCallback(
		(inventoryId: string) => {
			return apiWms.post(`/inventory/trail/${inventoryId}`, { filter });
		},
		[filter]
	);

	const fetchInventoryDetails = useCallback(async () => {
		try {
			setTrailLoading(true);
			const { data } = await getInventoryDetail(inventoryId);
			setPageCount(data?.count || 0);
			setInventoryData({ warehouseId: data?.warehouseId, wsin: data?.wsin, companyId: data?.companyId });
			setInventoryTrailData(data?.trail);
		} catch (error) {
			errorHandler(error);
			setInventoryData(null);
			setInventoryTrailData([]);
		} finally {
			setTrailLoading(false);
		}
	}, [inventoryId, getInventoryDetail]);

	const fetchScannedStockTrail = useCallback(async () => {
		try {
			setTrailLoading(true);
			const { data } = await apiWms.post(`/inventory/scanned-stock/trail/${wsin}`);
			if (data?.status) {
				setScannedStockTrail(data?.trails);
			}
		} catch (error: any) {
			errorHandler(error);
		} finally {
			setTrailLoading(false);
		}
	}, [wsin]);

	const InventoryMenuItems = useMemo(() => {
		const menuLine = [
			{ label: 'Inventory Inbound', key: '1', icon: <FileAddOutlined /> },
			{
				label: 'Inventory adjustment',
				key: '2',
				icon: <FileOutlined />,
				default: true
			}
		];
		return !isWMS ? menuLine : [...menuLine, { label: 'Update Scanned Stock', key: '3', icon: <BarcodeOutlined /> }];
	}, [isWMS]);

	const inventoryCountColumnArray = useMemo(
		() =>
			getInventoreyStatsMapper(
				shouldShowInventoryAdjustForm,
				shouldShowInventoryAdjustForm === '1' ? inventoryInboundFormData : inventoryAdjustFormData
			),
		[shouldShowInventoryAdjustForm, inventoryInboundFormData, inventoryAdjustFormData]
	);

	const handlePaginationChange = (page: number) => {
		setFilter((prevData) => ({
			...prevData,
			page
		}));
	};

	useEffect(() => {
		fetchInventoryDetails();
		isWMS && fetchScannedStockTrail();
	}, [fetchInventoryDetails, fetchScannedStockTrail, isWMS]);

	return (
		<>
			<Modal
				title={`Inventory Details for WSIN - ${inventoryData?.wsin || ''}`}
				centered
				visible={Boolean(inventoryId)}
				onOk={onOk}
				onCancel={onClose}
				width={1050}
				bodyStyle={{ height: 575, padding: 0 }}
				footer={null}
			>
				<Row>
					<Col span={16}>
						<InventoryFilter filter={filter} setFilter={setFilter} />
						<div className={styles.countBox}>
							{inventoryCountColumnArray.map((e) => (
								<div>
									<p className={styles.countName}>{e.name}</p>
									<p style={{ color: e.color }} className={styles.countValue}>
										{e.value}
									</p>
								</div>
							))}
						</div>
						<Menu mode="horizontal" defaultSelectedKeys={[shouldShowInventoryAdjustForm]}>
							{InventoryMenuItems.map(({ label, key, icon }) => {
								return (
									<Menu.Item onClick={() => setShouldShowInventoryAdjustForm(key)} key={key} icon={icon}>
										<Button type="text" size="small">
											{label}
										</Button>
									</Menu.Item>
								);
							})}
						</Menu>
						<InventoryFormHandler
							reloadList={reloadList}
							inventoryInboundFormData={inventoryInboundFormData}
							setInventoryInboundFormData={setInventoryInboundFormData}
							reloadInventory={fetchInventoryDetails}
							inventoryId={inventoryId}
							formKey={shouldShowInventoryAdjustForm}
							setInventoryAdjustFormDatarmData={setInventoryAdjustFormData}
							inventoryAdjustFormDatarmData={inventoryAdjustFormData}
							scannedStockWsin={wsin}
							fetchScannedStockTrail={fetchScannedStockTrail}
							setReloadInventoryList={setReloadInventoryList}
						/>
					</Col>
					<Col span={8} className={styles.trailColumn}>
						<Button
							type="primary"
							onClick={() => {
								window.open(
									`${wmsBaseUrl}/inventory/csv/inventory-trail?warehouseId=${inventoryData?.warehouseId}&wsin=${inventoryData?.wsin}&companyId=${inventoryData?.companyId}&appType=${appType}`,
									'_blank'
								);
							}}
						>
							<span>{exportSvg}</span>
							<span style={{ marginLeft: '.4rem', fontSize: '0.9rem' }}>Export Inventory Trail</span>
						</Button>
						<Menu mode="horizontal" defaultSelectedKeys={[trailType]}>
							{TrailType.map(({ label, key, icon }) => {
								return (
									<Menu.Item
										onClick={() => {
											setTrailType(key);
										}}
										key={key}
										icon={icon}
									>
										<Button type="text" size="small">
											{label}
										</Button>
									</Menu.Item>
								);
							})}
						</Menu>
						{trailLoading ? (
							<Loading loading={trailLoading} />
						) : Boolean(trailType === '1' ? inventoryTrailData.length : scannedStockTrail.length) ? (
							<>
								<div className={styles.inventoryTrail}>
									<div style={{ overflow: 'scroll', height: '93%', padding: '1rem' }}>
										<Timeline>
											{sortByDate({
												data: trailType === '1' ? inventoryTrailData : scannedStockTrail,
												fieldName: 'createdAt',
												sortFilter: 'DESC'
											}).map((trailDetails: any, index: any) => (
												<InventoryTrail trailDetails={trailDetails} key={index} />
											))}
										</Timeline>
									</div>
									<div style={{ paddingTop: '0.5rem' }}>
										<Pagination
											onChange={handlePaginationChange}
											simple
											defaultCurrent={filter.page}
											total={pageCount}
											pageSize={50}
										/>
									</div>
								</div>
							</>
						) : (
							<div style={{ display: 'flex', alignItems: 'center', height: '480px', justifyContent: 'center' }}>
								No Trail Exists
							</div>
						)}
					</Col>
				</Row>
			</Modal>
		</>
	);
};
